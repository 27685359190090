











































































import Vue from 'vue';
import { Faq, FaqType } from '@/domain/faqs/model/Faq';
import { Table, TableColumn } from 'element-ui';
import { FaqService } from '@/domain/faqs/service/FaqService';
import { apolloClient } from '@/apolloClient';
import { isHostAdmin } from '@/env';

const faqService = new FaqService(apolloClient);
const EVENT = 'EVENT';
const FAQS = 'FAQS';

export default Vue.extend({
  name: 'FaqTableForm',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    faqs: {
      type: Array,
      default: () => [],
    },
    typeList: Array,
    forHostOnly: Boolean,
  },
  methods: {
    isHostAdmin,
    async deleteFaq(row: Faq): Promise<void> {
      if (!row.id) {
        this.$modal.show({
          title: '자주 묻는 질문 삭제 실패!',
          type: 'warning',
          message: '자주 묻는 질문을 삭제 할 수 없습니다.',
        });
      }

      this.$modal.show(
        {
          title: '자주 묻는 질문 삭제!',
          type: 'info',
          message: '자주 묻는 질문을 삭제하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await faqService.deleteFaq(row.id);

            this.$modal.show(
              {
                title: '자주 묻는 질문 삭제 성공!',
                type: 'success',
              },
              () => {
                location.reload();
              }
            );
          } catch (err) {
            console.log('err =>', err);
            this.$modal.show({
              title: '자주 묻는 질문 삭제 실패!',
              type: 'warning',
              message: '자주 묻는 질문을 삭제 할 수 없습니다.',
            });
          }
        }
      );
    },
    detailFaq(row: Faq): void {
      const params = {
        id: row.id.toString(),
        forHostOnly: this.forHostOnly.toString(),
      };

      this.$router.push({
        name: 'FaqDetail',
        params: params,
      });
    },
    detailHostFaq(row: Faq): void {
      const params = {
        id: row.id.toString(),
        question: row.question.toString(),
        answer: row.answer.toString(),
      };

      this.$router.push({
        name: 'HostFaqDetail',
        params: params,
      });
    },
    convertToTypeName(type: string): string {
      return FaqType[type];
    },
    checkedType(type: string): string {
      switch (true) {
        case type.includes(FAQS):
          return 'primary';

        case type.includes(EVENT):
          return 'success';

        default:
          return 'danger';
      }
    },
  },
});
