var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center center-head",staticStyle:{"text-align":"center","width":"100%"},attrs:{"data":_vm.faqs,"header-cell-style":{ textAlign: 'center' },"header-row-class-name":"thead-light","header-cell-class-name":"center-table","border":""}},[_c('el-table-column',{attrs:{"label":"ID","prop":"id","min-width":10,"class-name":"text-center"}}),_c('el-table-column',{attrs:{"label":"카테고리","min-width":15,"class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-badge',{attrs:{"type":_vm.checkedType(row.type),"rounded":false}},[_vm._v(_vm._s(_vm.convertToTypeName(row.type))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"질문","prop":"question","min-width":80,"class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"custom-el-wrapper",staticStyle:{"color":"#7a29fa","cursor":"pointer"},on:{"click":function($event){_vm.isHostAdmin() ? _vm.detailHostFaq(row) : _vm.detailFaq(row)}}},[_vm._v(" "+_vm._s(row.question)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"정렬번호","prop":"sort","min-width":10,"class-name":"text-center"}}),_c('el-table-column',{attrs:{"label":"등록시간","prop":"createdAt","min-width":35,"class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"el-icon-time"}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')))])]}}])}),_c('el-table-column',{attrs:{"label":"삭제","min-width":25,"class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{staticClass:"btn frip-button my-1 btn-outline-frip-default btn-sm",attrs:{"type":"warning"},on:{"click":function($event){return _vm.deleteFaq(row)}}},[_vm._v(" 삭제 ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }